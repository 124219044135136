export class ColumnHeader {
  constructor(
    public pathToValue: string,
    public label: string,
    public visible = true,
    public columnWidth = ''
  ) {}
}

export enum TableSelectionMode {
  Single = 'SINGLE',
  Multiple = 'MULTIPLE'
}
