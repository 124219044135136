<table class="table table-sm table-bordered table-hover no-select text-center">
  <thead class="thead-color-from-db">
    <tr>
      <th scope="col">#</th>
      @for (columnMap of columnMaps; track columnMap) {
        <th
          scope="col"
          [style.width]="columnMap.columnWidth"
          >
          {{ columnMap.label | translate }}
        </th>
      }
    </tr>
  </thead>
  <tbody>
    @if (isLoading()) {
      <tr>
        <td [colSpan]="columnMaps.length + 1">
          @if (isLoading()) {
            <div class="d-flex justify-content-center my-3">
              <div class="spinner-border" role="status">
                <span class="visually-hidden">{{ 'LOADING' | translate }}</span>
              </div>
            </div>
          }
        </td>
      </tr>
    } @else {
      @if (shouldShowEmptyRequiredRow()) {
        <tr
          class="required empty-row"
          aria-required="true"
          >
          <td></td>
          @for (cellMap of columnMaps; track cellMap) {
            <td aria-required="true"></td>
          }
        </tr>
      }
      @for (record of records; track record; let i = $index) {
        <tr
          (click)="handleRowSelection(record, $event, i)"
          class="clickable-table-row"
          [class.selected-row]="isSelected(record)"
          >
          <td>{{ i + 1 }}</td>
          <ng-container
            [ngTemplateOutlet]="preCellTemplate"
            [ngTemplateOutletContext]="{ record: record, index: i }"
            >
          </ng-container>
          @for (cellMap of cellMaps; track cellMap) {
            <td>
              <span>{{
                record[cellMap.pathToValue] &&
                record[cellMap.pathToValue].toString()
                | translate
                | dateWithoutTime
              }}</span>
            </td>
          }
          <ng-container
            [ngTemplateOutlet]="dictionaryFieldsTemplate"
            [ngTemplateOutletContext]="{ record: record }"
            >
          </ng-container>
          <ng-container
            [ngTemplateOutlet]="cellTemplate"
            [ngTemplateOutletContext]="{ record: record }"
          ></ng-container>
        </tr>
      }
    }
  </tbody>
</table>
